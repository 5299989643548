@media (max-width: 768px) {
    .MuiCardHeader-root {
        flex-direction: column;
        .MuiCardHeader-conten,
        .MuiCardHeader-action {
            text-align: center;
            width: 100%;
        }
        .MuiCardHeader-content {
            margin-bottom: .5rem;
        }
    }
}

.subject-input-box,
.spaced-textarea {
    .MuiOutlinedInput-input {
        padding-right: 7.5rem
    }
}

.smsresponse {
    .MuiInput-input {
        padding-right: 6.5rem
    }
}

.shadow-custom {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}

#notification-menu {
    .MuiMenu-list {
        height: calc(100vh - 100px);
        overflow-y: auto;
    }
}

.audio-bg-filter {
    filter: sepia(50%) saturate(70%) grayscale(10) contrast(99%) invert(12%);
}

.wizard-item {
    &:first-child {
        .text-xs {
            left: -25px;
        }
    }
    &:last-child {
        .text-xs {
            right: -15px;
        }
    }
}

.twoLinesClamp{
   word-break: break-word;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 16px; /* fallback */
   max-height: 32px; /* fallback */
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}